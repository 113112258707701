<template>
  <section class="products">
    <v-img
      v-if="category && category.cat.banner !== null"
      :src="category.cat.banner"
      class="top-banner"
    >
    </v-img>
    <v-container  grid-list-md text-center id="ctk_top" style="margin-bottom:10px">
      <template>
        <div>
          <v-breadcrumbs :items="breadcrumbs" divider=" / "></v-breadcrumbs>
        </div>
      </template>
      <v-container
        fluid
        grid-list-sm
        mb-12
      >
        <v-row wrap v-if="category && category.attr" class="text-center">
          <v-col cols="12">
            <v-row>
              <v-flex  xs12 sm12 md2 lg2 ma-2 v-for="(attr, i) in category.attr" :key="i">
                <v-select
                  class="ctk-select"
                  v-model="pAttr"
                  :items="attr.values"
                  :label="attr.name"
                  multiple
                  chips
                  persistent-hint
                  selectSlot
                ></v-select>
              </v-flex>
              <v-flex  xs12 sm12 md3 lg3 ma-3 >
                <v-text-field
                  hide-details
                  outlined
                  dense
                  single-line
                  @keyup.enter="search"
                  append-icon="search" label="请输入产品名称，按回车键" class="pa-0">
                </v-text-field>
              </v-flex>
            </v-row>
          </v-col>
        </v-row>
        <v-layout wrap v-if="products && products.data">
          <template v-for="(cs, i) in products.data">
            <v-flex
              :key="i"
              xs12
              md4
              pa-2
            >
              <template>
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <v-card
                      class="mx-auto"
                    >
                      <v-img
                        :src="cs.img"
                        aspect-ratio="1"
                        max-height="470px"
                      ></v-img>
                      <v-fade-transition style="width:100%; overflow: hidden">
                        <v-overlay
                          v-if="hover"
                          absolute
                          color="#036358"
                          class="text-left"
                          style="padding:20px;"
                        >
                          <p style="font-size: 24px; color: #FFF;word-wrap:break-word;word-break:break-all;">{{cs.title}}</p>
                          <v-btn class="ma-2" outlined style="border:none;" :href="'/products/detail/' + cs.id"> {{detail}}> </v-btn>
                        </v-overlay>
                      </v-fade-transition>
                    </v-card>
                  </template>
                </v-hover>
              </template>
            </v-flex>
          </template>
        </v-layout>
      </v-container>
      <template>
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="totalPage"
            circle
            style="background: #FFF !important;"
          ></v-pagination>
        </div>
      </template>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 3,
    locale: null,
    detail: null,
    category: null,
    products: null,
    breadcrumbs: [],
    totalPage: null,
    page: null,
    pAttr: null,
    keywords: null
  }),
  created () {
    document.querySelector('#menu_product').classList.add('v-btn--active')
    this.locale = localStorage.getItem('locale')
    this.getPageData()
    this.getCategory()
    this.page = 1
  },
  watch: {
    category: {
      handler: function (val, oldVal) {
        this.parseBreadcrumbs(val)
      },
      deep: true
    },
    params: {
      handler: function (val, oldVal) {
        document.querySelector('#ctk_top').scrollIntoView(true)
        this.getProducts(this.$route.params.id, val)
      },
      deep: true
    }
  },
  computed: {
    params () {
      const { pAttr, page } = this
      return {
        pAttr,
        page
      }
    }
  },
  methods: {
    getPageData () {
      this.detail = this.$t('detail')
    },
    getCategory () {
      this.https.get('product_category', { pid: this.$route.params.pid, id: this.$route.params.id }).then(response => {
        if (response.code === 0) {
          this.category = response.data
        }
      })
    },
    getProducts (cid, params) {
      params.cid = this.$route.params.id
      if (this.keywords != null) {
        params.keywords = this.keywords
      }
      this.https.get('products', params).then(response => {
        if (response.code === 0) {
          this.products = response.data
          this.totalPage = this.products.last_page
          this.page = params.page
        }
      })
    },
    parseBreadcrumbs (category) {
      this.breadcrumbs = []
      this.breadcrumbs.push({ text: this.$t('products.name'), disabled: false, href: '/products' })
      let cateName = 'name'
      if (this.locale !== 'cn' && this.locale !== null) {
        cateName = cateName + '_' + this.locale
      }
      if (category.pcat) {
        this.breadcrumbs.push({ text: category.pcat[cateName], disabled: false, href: '/products/' + category.pcat.id })
      }
      if (category.cat) {
        this.breadcrumbs.push({ text: category.cat[cateName], disabled: true, href: '/products/' + category.pcat.id + '/' + category.cat.id })
      }
    },
    // 回车键搜索
    search (e) {
      this.keywords = e.target.value
      this.getProducts(this.$route.params.id, this.params)
    }
  }
}
</script>
