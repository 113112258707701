import { render, staticRenderFns } from "./Products2.vue?vue&type=template&id=a07ab7a6&"
import script from "./Products2.vue?vue&type=script&lang=js&"
export * from "./Products2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBreadcrumbs,VBtn,VCard,VCol,VContainer,VFadeTransition,VFlex,VHover,VImg,VLayout,VOverlay,VPagination,VRow,VSelect,VTextField})
